<div class="top-of-form"></div>
<merchant-app-card
  [stepNumber]="!forConsole ? this.stepNumber + 1 : null"
  [title]="!forConsole ? 'Payment Processing Volume' : null"
  [description]="!forConsole ? 'Enter your current or projected processing volumes' : null"
  [backButton]="!forConsole"
  (backClick)="onBackClicked($event)"
  (continueClick)="onContinueClicked($event)"
  [continueButton]="!forConsole && !(disabled$ | async)"
>
  <form data-test-id="processing-volumes-step" class="w-full space-y-4" [formGroup]="processingVolumesForm">
    <div class="items-left flex flex-col pt-2.5">
      <tilled-heading-h4>Processing Volumes and History</tilled-heading-h4>
      <tilled-paragraph-p3 [secondary]="true" class="pt-3">
        Estimate the amount of money your business handles in credit card and bank to bank payments.
      </tilled-paragraph-p3>
    </div>
    <div class="flex w-full flex-col">
      <tilled-input
        data-test-id="average-monthly-transactions"
        name="averageMonthlyTransactions"
        mask="separator.0"
        label="Number of Transactions per month"
        tilledLabel="true"
        hint="Estimate the average number of credit card or bank to bank transactions you make in a month."
        [errors]="
          processingVolumesForm.get('averageMonthlyTransactions').hasError('min') &&
          processingVolumesForm.get('averageMonthlyTransactions').touched
        "
        errorMessage="Must be greater than 0"
      ></tilled-input>
    </div>
    <div class="flex w-full flex-col">
      <tilled-input
        data-test-id="average-monthly-volume"
        name="averageMonthlyVolume"
        showCurrency="'true'"
        mask="separator.0"
        label="Monthly Processing Volume"
        tilledLabel="true"
        hint="Estimate the average amount you make in a month from credit card and bank to bank sales."
        [errors]="
          processingVolumesForm.get('averageMonthlyVolume').hasError('min') &&
          processingVolumesForm.get('averageMonthlyVolume').touched
        "
        errorMessage="Must be greater than $0"
      ></tilled-input>
    </div>
    <div class="flex w-full flex-col">
      <div class="w-full pb-2.5 pt-4">
        <tilled-label-l1 secondary="true">What was the name of your last payment processor?</tilled-label-l1>
      </div>
      <tilled-select
        classOverride="w-full"
        data-test-id="previous-processor"
        [multiple]="false"
        [options]="previousProcessorMap"
        (selectionChange)="onPreviousProcessorChange()"
        [controlName]="processingVolumesForm.get('previousProcessor')"
        [placeholder]="'Choose One'"
      ></tilled-select>
    </div>
    <!-- Processor Details -->
    <div *ngIf="showPreviousProcessorOther$ | async" class="flex w-full flex-col">
      <tilled-input
        data-test-id="previous-processor-other"
        name="previousProcessorOther"
        label="Please enter the name of your last payment processor"
        tilledLabel="true"
      ></tilled-input>
    </div>
    <div class="flex w-full flex-col">
      <div class="w-full pb-2.5 pt-4">
        <tilled-label-l1 secondary="true"
          >On average, how long after paying will your customers typically receive your goods or
          services?</tilled-label-l1
        >
      </div>
      <tilled-select
        classOverride="w-full"
        data-test-id="days-prior-to-shipping"
        [multiple]="false"
        [options]="shippingTimesTypesMap"
        [controlName]="processingVolumesForm.get('daysPriorToShipping')"
        [placeholder]="'Choose One'"
      ></tilled-select>
    </div>
    <!-- Transaction Percentages -->
    <div class="items-left flex flex-col pt-4">
      <tilled-heading-h4>Checkout Methods</tilled-heading-h4>
      <tilled-paragraph-p3 [secondary]="true" class="pt-3">
        What percent of your total transactions are processed through each of these checkout methods?
      </tilled-paragraph-p3>
      <!--div class="w-full pt-4">
        <tilled-paragraph-p2 [medium]="true"
          >What percent of your total transactions are processed through each of these checkout
          methods?</tilled-paragraph-p2
        >
      </div-->
      <div class="w-full pb-4">
        <tilled-paragraph-p3
          [secondaryText]="true"
          [warn]="true"
          *ngIf="
            processingVolumesForm.get('percentsFormGroup').errors &&
            processingVolumesForm.get('percentsFormGroup').errors.percentagesSumTo100
          "
        >
          Must add up to 100%. Accept whole numbers only.
        </tilled-paragraph-p3>
      </div>
      <tilled-input
        data-test-id="card-present"
        name="cardPresent"
        groupName="percentsFormGroup"
        [sentenceCaseTitle]="false"
        label="In-person/Card-present"
        tilledLabel="true"
        minValue="0"
        maxValue="100"
        mask="percent"
        placeholder="Enter %"
        [showPercent]="true"
        toolTip="You take payments in person, using a card reader"
      ></tilled-input>
      <tilled-input
        data-test-id="e-commerce"
        name="eCommerce"
        groupName="percentsFormGroup"
        [sentenceCaseTitle]="false"
        label="Website/E-commerce"
        tilledLabel="true"
        minValue="0"
        maxValue="100"
        mask="percent"
        placeholder="Enter %"
        [showPercent]="true"
        toolTip="You take payments through a website"
      ></tilled-input>
      <tilled-input
        data-test-id="card-not-present"
        name="cardNotPresent"
        groupName="percentsFormGroup"
        label="Over the phone"
        tilledLabel="true"
        minValue="0"
        maxValue="100"
        mask="percent"
        placeholder="Enter %"
        [showPercent]="true"
        toolTip="Your customers call you and you enter their payment details while on the phone"
      ></tilled-input>
    </div>
    <!-- Average Transaction Amounts -->
    <div class="items-left flex flex-col pt-4">
      <tilled-heading-h4>Average Transaction Amounts</tilled-heading-h4>
      <tilled-paragraph-p3 [secondary]="true" class="pt-3">
        Accurately estimating your average transaction amounts is crucial to prevent delays in fund disbursement. If you
        process a wide range of transaction amounts, consider estimating your average on the higher end to avoid delays
        in fund disbursement.
      </tilled-paragraph-p3>
    </div>
    <div class="flex w-full flex-col" *ngIf="hasCard">
      <tilled-input
        data-test-id="average-transaction-amount-card"
        name="averageTransactionAmountCard"
        showCurrency="'true'"
        mask="separator.0"
        label="Credit Card - Average Transaction Amount"
        tilledLabel="true"
        hint="Estimate the typical amount for a single credit card sale or transaction."
        [errors]="
          processingVolumesForm.get('averageTransactionAmountCard').hasError('min') &&
          processingVolumesForm.get('averageTransactionAmountCard').touched
        "
        errorMessage="Must be greater than $0"
      ></tilled-input>
    </div>
    <div class="flex w-full flex-col" *ngIf="hasDebit">
      <tilled-input
        data-test-id="average-transaction-amount-debit"
        name="averageTransactionAmountDebit"
        showCurrency="'true'"
        mask="separator.0"
        label="Bank to Bank - Average Transaction Amount"
        tilledLabel="true"
        hint="Estimate the typical amount for a single bank to bank sale or transaction, where the customers pay using their bank account details."
        [errors]="
          processingVolumesForm.get('averageTransactionAmountDebit').hasError('min') &&
          processingVolumesForm.get('averageTransactionAmountDebit').touched
        "
        errorMessage="Must be greater than $0"
      ></tilled-input>
    </div>
  </form>
</merchant-app-card>
