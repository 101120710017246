<div class="top-of-form"></div>
<merchant-app-card
  [stepNumber]="!forConsole ? this.stepNumber + 1 : null"
  [title]="!forConsole ? 'Business Documents' : null"
  [description]="!forConsole ? 'Help us verify your business by uploading documents' : null"
  [backButton]="false"
  (continueClick)="onContinueClicked($event)"
  [continueButton]="!forConsole && !(disabled$ | async)"
>
  <div class="items-left flex flex-col pt-2.5">
    <tilled-heading-h4>Business Documents</tilled-heading-h4>
    <tilled-paragraph-p3 [secondary]="true" class="pt-3">
      Please provide an Articles of Incorporation or a Business License to help us verify your business. You can also
      upload a photo of the document, as long as it's readable.
    </tilled-paragraph-p3>
  </div>
  <form data-test-id="details-step" class="w-full" [formGroup]="businessDocumentsForm">
    <!-- Business Documents/Patriot Act Details-->
    <div class="items-left flex flex-col pt-8">
      <div class="flex w-full flex-col">
        <tilled-paragraph-p2 class="pt-3" [medium]="true"
          >Will you provide an Articles of Incorporation or a Business License?</tilled-paragraph-p2
        >
        <mat-radio-group
          data-test-id="details-business-docs-check"
          class="flex flex-col pt-2"
          color="primary"
          formControlName="businessDocsCheck"
          (change)="onBusinessDocsChange()"
        >
          <mat-radio-button [value]="true">Articles of Incorporation</mat-radio-button>
          <mat-radio-button [value]="false">Business License</mat-radio-button>
        </mat-radio-group>

        <!-- Articles of Incorporation -->
        <div [ngClass]="{ hidden: !(showArticlesOfIncorp$ | async) }" class="pt-8">
          <div class="w-full">
            <tilled-paragraph-p1 [medium]="true">Details about your Articles of Incorporation</tilled-paragraph-p1>
          </div>
          <tilled-input
            data-test-id="details-aoi-issued-at"
            name="articlesOfIncorpIssuedAt"
            label="Issue date"
            placeholder="MM/DD/YYYY"
            [sentenceCasePlaceholder]="false"
            tilledLabel="true"
            mask="M0/d0/0000"
            [dropMaskCharacters]="false"
            [leadZeroDateTime]="true"
            [errors]="businessDocumentsForm.get('articlesOfIncorpIssuedAt').errors?.['mask']"
            errorMessage="Issue date must be a valid date."
          ></tilled-input>
          <div class="w-full pb-2.5 pt-4">
            <tilled-label-l1 secondary="true">State</tilled-label-l1>
          </div>
          <tilled-select
            [multiple]="false"
            [options]="stateCodeMap"
            [placeholder]="'Choose One'"
            [controlName]="businessDocumentsForm.get('articlesOfIncorpState')"
            classOverride="w-full pb-6"
            data-test-id="details-aoi-state"
          ></tilled-select>
          <div
            *ngIf="
              businessDocumentsForm.get('articlesOfIncorpDocumentId').value != null &&
              businessDocumentsForm.get('articlesOfIncorpDocumentId').value != 'not_required'
            "
            class="mb-4 flex w-100 flex-col rounded-3xl border border-solid border-tilled-neutral-400 sm:pl-6 sm:pr-2"
          >
            <div class="flex h-[70px] flex-row items-center">
              <mat-icon class="text-green-500 icon-size-5" svgIcon="heroicons_solid:check"></mat-icon>
              <tilled-paragraph-p3 [bold]="true" [truncate]="true" class="overflow-hidden whitespace-nowrap pl-2"
                >Articles of Incorporation uploaded</tilled-paragraph-p3
              >
              <div class="ml-auto flex cursor-pointer flex-row pr-4">
                <button
                  mat-icon-button
                  (click)="removeUploadedDocument(businessDocumentsForm.get('articlesOfIncorpDocumentId').value)"
                  matTooltip="Delete uploaded document"
                >
                  <mat-icon class="text-tilled-primary' icon-size-5" svgIcon="heroicons_solid:trash"></mat-icon>
                </button>
              </div>
            </div>
          </div>
          <app-account-documents-upload
            #documentsComponentAOI
            [accountId]="account?.id"
            [forConsole]="false"
            [filterDocType]="'articles_of_incorp'"
            [limit]="1"
            [overrideCheckbox]="true"
            submitText="Upload Articles of Incorporation"
            (lastRequestedDocumentDate)="(null)"
            (submittedDocumentsResponse)="documentSubmitted($event)"
          ></app-account-documents-upload>
        </div>

        <!-- Business License -->
        <div [ngClass]="{ hidden: !(showBusinessLicense$ | async) }" class="pt-8">
          <div class="w-full">
            <tilled-paragraph-p1 [medium]="true">Details about your Business License</tilled-paragraph-p1>
          </div>
          <tilled-input
            data-test-id="details-business-license-issued-at"
            name="businessLicenseIssuedAt"
            label="Issue date"
            placeholder="MM/DD/YYYY"
            [sentenceCasePlaceholder]="false"
            tilledLabel="true"
            mask="M0/d0/0000"
            [dropMaskCharacters]="false"
            [leadZeroDateTime]="true"
            [errors]="businessDocumentsForm.get('businessLicenseIssuedAt').errors?.['mask']"
            errorMessage="Issue date must be a valid date."
          ></tilled-input>
          <tilled-input
            data-test-id="details-business-license-expires-at"
            name="businessLicenseExpiresAt"
            label="Expiry date"
            placeholder="MM/DD/YYYY"
            [sentenceCasePlaceholder]="false"
            tilledLabel="true"
            mask="M0/d0/0000"
            [dropMaskCharacters]="false"
            [leadZeroDateTime]="true"
            [errors]="businessDocumentsForm.get('businessLicenseExpiresAt').errors?.['mask']"
            errorMessage="Expiry date must be a valid date."
          ></tilled-input>
          <div class="w-full pb-2.5 pt-4">
            <tilled-label-l1 secondary="true">State</tilled-label-l1>
          </div>
          <tilled-select
            [multiple]="false"
            [options]="stateCodeMap"
            [placeholder]="'Choose One'"
            [controlName]="businessDocumentsForm.get('businessLicenseState')"
            classOverride="w-full pb-6"
            data-test-id="details-business-license-state"
          ></tilled-select>
          <div
            *ngIf="
              businessDocumentsForm.get('businessLicenseDocumentId').value != null &&
              businessDocumentsForm.get('businessLicenseDocumentId').value != 'not_required'
            "
            class="mb-4 flex w-100 flex-col rounded-3xl border border-solid border-tilled-neutral-400 sm:pl-6 sm:pr-2"
          >
            <div class="flex h-[70px] flex-row items-center">
              <mat-icon class="text-green-500 icon-size-5" svgIcon="heroicons_solid:check"></mat-icon>
              <tilled-paragraph-p3 [bold]="true" [truncate]="true" class="overflow-hidden whitespace-nowrap pl-2"
                >Business License uploaded</tilled-paragraph-p3
              >
              <div class="ml-auto flex cursor-pointer flex-row pr-4">
                <button
                  mat-icon-button
                  (click)="removeUploadedDocument(businessDocumentsForm.get('businessLicenseDocumentId').value)"
                  matTooltip="Delete uploaded document"
                >
                  <mat-icon class="text-tilled-primary' icon-size-5" svgIcon="heroicons_solid:trash"></mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="account$ | async as merchantAccount">
            <app-account-documents-upload
              #documentsComponentBL
              [accountId]="merchantAccount.id"
              [forConsole]="false"
              [filterDocType]="'business_license'"
              [limit]="1"
              [overrideCheckbox]="true"
              submitText="Upload Business License"
              (lastRequestedDocumentDate)="(null)"
              (submittedDocumentsResponse)="documentSubmitted($event)"
            ></app-account-documents-upload>
          </div>
          <div *ngIf="!(account$ | async)">
            <app-account-documents-upload
              #documentsComponentBL
              [accountId]="account?.id"
              [forConsole]="false"
              [filterDocType]="'business_license'"
              [limit]="1"
              [overrideCheckbox]="true"
              submitText="Upload Business License"
              (lastRequestedDocumentDate)="(null)"
              (submittedDocumentsResponse)="documentSubmitted($event)"
            ></app-account-documents-upload>
          </div>
        </div>
      </div>
    </div>
  </form>
</merchant-app-card>
